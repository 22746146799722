import { useRouteError } from "react-router-dom";
import React from 'react';

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <>
    <div class="max-w-lg mx-auto mt-40 bg-white rounded-2xl shadow-lg space-y-2 sm:py-10  sm:items-center sm:space-y-0 " id="error-page">
      <h1 class="text-2xl text-center text-black font-semibold">Knock that off!</h1>
      <p class="pt-2 text-center">This page doesn't exist yet, stop snooping around! </p>
    </div>
    <div class="max-w-lg mx-auto mt-40 bg-white rounded-2xl space-y-2 sm:py-10  sm:items-center sm:space-y-0 ">
        <p class="pt-0 text-center flex-shrink-0">jk sorry for any inconvenience</p>
    </div>
    </>
  );
}