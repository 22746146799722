import React, { useState, useEffect }  from "react";
import cloudclassifierpic from "./assets/cloudclassifierpic.jpeg";
import CloudPredict from "./cloud_predict";


export default function Projects() {
  function handleClick(e) {
    console.log("clicked");
    if (e.target.id === 'cloud_card') {
      window.location.href = '/cloud_classifier';
  }
}
    return (
        <div>
            <h1 class="text-center font-bold">Projects</h1>
            <section class = "flex items-center flex-grow" id="projects">
              <div class="p-10" onClick={handleClick}>
                <div class="border m-10 transform h-72 bg-blue-400 w-80 transition duration-400 hover:scale-110 hover:bg-blue-600 flex justify-center items-center"  id="product-image">
                  <img onClick={handleClick} class="cursor-pointer " id="cloud_card" src={cloudclassifierpic}/>
                </div>
                <div class="text-center" id="product-info">
                  <h2>Cloud Classifier</h2>
                  <p>Classify clouds with a convolutional neural network</p>
                </div>
              </div>
              <div class="p-10" onClick={handleClick}>
                <div class="border m-10 transform h-72 bg-blue-400 w-80 transition duration-400 hover:scale-110 hover:bg-blue-600 flex justify-center items-center"  id="fastail3-image">
                </div>
                <div class="text-center" id="product-info">
                  <h2>FastAI L3</h2>
                </div>
              </div>
            </section>
        </div>
    );
}
