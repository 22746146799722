import React, { useState, useEffect }  from "react";
import axios from "axios";

export default function CloudPredict() {
    return (
        <>
        <div class="text-center">
          <h1 class="pt-10 font-bold">Cloud classifier! </h1>
          <p class="pt-10"> Figure out what kind of cloud you are looking at. Important to know if you are: </p>
          <ol class="pt-4">
            <li> a paraglider </li>
            <li> a pilot </li>
            <li> on a mountain </li>
            <li> a huge nerd</li>
          </ol>
        </div>
        <DragDropFile/>
        </>
    );
}

function DragDropFile() {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    const [uploadedImage, setUploadedImage] = React.useState(false);
    const [fileUrl, setFileUrl] = React.useState(null);
    const [cloudCategory, setCloudCategory] = React.useState(null);
    const [cloudConfidence, setCloudConfidence] = React.useState(null);
    const [image, setImage] = useState();
    const [file, setFile] = React.useState(null);
    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function(e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFile(e.dataTransfer.files);
      }
    };

    const handleFile = function(files) {
        setFile(files[0])
        setUploadedImage(true);
    }

    const handleClick = () => {
        // open file input on click of button
        inputRef.current.click(); 
    }

    const inputChange = (e) => {
      setFile(e.target.files[0]);
      setFileUrl(URL.createObjectURL(e.target.files[0]));
    }

    async function submit(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("afile", file, file.name);
        axios.post('/predict_cloud', formData)
        .then(res => {
            setCloudCategory(res.data.prediction_category); 
            let json_confidence = JSON.parse(res.data.tensor);
            let largest_confidence = Math.max(...json_confidence);
            setCloudConfidence(largest_confidence * 100);

        }); 
            //this.setState({ cloudProbability: res.data.prediction_probability});
        // on response, we need to do some shiz
    }



    return (
        <>
      <form class=" mt-10 h-64 w-96 max-w-full text-center relative" id="form-file-upload" onDragEnter={handleDrag} onSubmit={submit}>
        <label id="label-file-upload" class="h-full flex items-center justify-center border-2 rounded-2xl border-dashed border-blue-400 bg-gray-400" htmlFor="input-file-upload" >
          <div>
            <p>Drag and drop your cloud image here or</p>
            <input style={{display: 'none'}} ref={inputRef} type="file" onChange={inputChange} />
            <button className="upload-button" filename={file} type="button" accept="image/*"  onClick={handleClick}  class="cursor-pointer p-1 rounded-2xl border rounded-2xl border-dashedtext-base bg-transparent"  >Upload an image</button>
            {file && <p>{file.name}</p>}
            {cloudCategory && <p> I think this is a {cloudCategory} cloud</p>}
            {cloudConfidence && <div><p>{cloudConfidence < 70 ? 'However, I am not really sure.': ''}</p>
            <p> I am {cloudConfidence < 70 ? 'only' : ''} {cloudConfidence}% confident. </p> 
            <p> {cloudConfidence < 70 ? 'I am prone to false positives. ' : ''}</p>
            </div>
            }
          </div>
        </label>
        {file && <button class="p-1 cursor-pointer border rounded-2xl bg-sky-500" type="submit" > Predict</button>}
        {fileUrl &&  <img class="pt-1 block ml-auto mr-auto w-6/12" src={fileUrl}/> }
        <div id="results"></div>
        { dragActive && <div id="drag-file-element" class="absolute w-full h-full rounded-lg top-0 right-0 bottom-0 left-0" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
      </form>
      {image && <img src={image} alt="cloud_upload" />}
      </>
    );
  };
