import React, { useState } from 'react';
import jacksface from './assets/jacksface.jpeg';

function Home() {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (e) => {
    if (e.target.id === 'projects-button') {
      window.location.href = '/projects';
      setIsActive(current => !current);

    }
  }

  return (
    <div class="max-w-m  bg-white rounded-xl  flex-wrap items-center space-x5 ">
      <ContactMe />
        <div class="sm:text-2xl px-1 pt-10 lg:pr-40 lg:pl-40 items-center" >
          <h3> Hello! I'm Jack </h3>
          <p class="pt-4 text-m font-thin text-black width-lg">This is my personal site, where I store some of my projects & other nifty things</p>
          <p class="pt-6 text-slate-500 "> I am a full stack developer with a lot of hobbies </p>
          <p class=""> Right now, I am working on ML/AI <button id="projects-button" class="py-1 px-1 rounded-xl"
        style={{
          backgroundColor: isActive ? 'white' : 'lightblue',
          color: isActive ? 'white' : 'black',
        }}
        onClick={handleClick}
      > projects</button>
         </p>
          <p class="text-slate-500"> <strike>I am interested in everything </strike></p>
          <p class="text-slate-500"> I am interested in <strike> </strike>  web development, mathematics, distributed systems, real estate, music theory, economics <strike> and anything shiny</strike></p>
          <AboutMe />
        </div>
    </div>

  )
}

function ContactMe() {
  const github_url = "https://github.com/Jp3stephens";
  const linked_in_url = "https://www.linkedin.com/in/jack-stephens-2020/";
  return (
    <div class="max-w-lg mx-auto bg-white rounded-2xl shadow-lg space-y-2 sm:py-10  sm:items-center sm:space-y-0 ">
      <img class="mx-auto h-40 rounded-full  " src={jacksface} alt="Jacks Face"></img>
      <div class="text-center">
        <div class="space-y-0.5">
          <p class="text-2xl text-black font-semibold">
            Jack Stephens
          </p>
          <p class="text-slate-500 font-small">
            Software Engineer
          </p>
        </div>
        <div class="items-center py-2 pb-2 ">
        <button class=" px-1 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2" onClick={() => window.open(github_url, '_blank')}>Github</button>
        <button class=" px-1 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2" onClick={() => window.open(linked_in_url, '_blank')}>LinkedIn</button>
        <button class=" px-1 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2" onClick={() => window.open("mailto:jp3stephens@gmail.com", '_blank')}> Email Me</button>
        </div>
      </div>
    </div>
  );
}

function AboutMe() {
  return (
    <div class="pt-4 pb-10">
      <small class="text-m font-thin text-black width-lg">
        <p class="pt-5 "> As of October 2022 I'm building & researching at the intersection of ML and music. </p>
        <p class="pt-2"> It turns out if you teach a computer music theory rules, you can train it to produce <a style={{color:'#3390b6' }} href="https://www.harmonai.org/">decent</a> sounding songs! </p>
        <p class="pt-2"> I'm excited about this and would love to speak with you if you're working in a similar area </p>
      </small>

      
      <p class="pt-10"> I'm a strong proponent of 'learning for learnings sake'</p>
      <p class="pt-3"> I think the best way to learn is through application.</p>
      <p class="pt-3"> I love reading books. I usually have my <a style={{color:'#3390b6' }} href="https://www.kobo.com/us/en">kobo</a> with me. </p>
      <p class="pt-10"> I currently work at a well-known mental health company. I think anyone who can afford it should<a style={{color:'#3390b6' }} href="https://www.betterhelp.com/advice/therapy/six-benefits-of-therapy/"> try therapy. </a>  </p>
      <p class="pt-3"> I'm enthralled with nature and spend a lot of my time on mountains. I crave <a style={{color:'#3390b6' }} href="https://www.rei.com/blog/climb/fun-scale">type II fun.</a> This part of my life has shaped me more than anything else</p>
      <p class="pt-10"> I believe that it's our responsibility as humans, to leave <strong> everything </strong> better than it was found -- whether that be a field of research, the internet, people you love, or the mountain you camped on last weekend.  </p>
      <p class="pt-10"> Glad to have ya here! </p>
    </div>

  )
}

export default Home;